/* eslint-disable no-underscore-dangle */
import { addScript } from '../../utils/domUtils';
import { conf } from '../configService';
import { domainService } from '../domainService';
import { eventService } from '../eventService';

let previousGroups;

export const oneTrustConsentService = {
  getGroups: () =>
    typeof window === 'object' ? window.OnetrustActiveGroups : '',

  // includes() is being correctly applied to a string (String.prototype.includes()).
  // Not to be confused with Array.prototype.includes().
  allowsFunctionalities: () =>
    oneTrustConsentService.getGroups().includes(conf.cmp_group_functionalities),

  allowsTargeting: () =>
    oneTrustConsentService.getGroups().includes(conf.cmp_group_targeting),

  allowsSocialMedia: () =>
    oneTrustConsentService.getGroups().includes(conf.cmp_group_social_media),

  allowsPerformance: () =>
    // Unlike www.* domains, cms-*.ngeo.com domains don't have "Performance" (C0002)
    // cookies enabled, but we need both domains to have the same configuration.
    // We manually set "Performance" to true if running on a cms-*.ngeo.com domain
    // and "Targeting" (C0004) is also enabled.
    oneTrustConsentService.getGroups().includes(conf.cmp_group_performance) ||
    (domainService.isRunningOnCMS() &&
      oneTrustConsentService.allowsTargeting()),

  getCmpSettings: () => {
    const data = {
      service_loaded: false
    };
    if (oneTrustConsentService.getGroups()) {
      data.service_loaded = true;
    }
    data.cmpPerformance = oneTrustConsentService.allowsPerformance();
    data.cmpFunctionalities = oneTrustConsentService.allowsFunctionalities();
    data.cmpTargeting = oneTrustConsentService.allowsTargeting();
    data.cmpSocialMedia = oneTrustConsentService.allowsSocialMedia();

    return data;
  },
  getPlayerData: () => {
    const cmpSettings = oneTrustConsentService.getCmpSettings();
    const data = {
      fng_player_gam_plugin: cmpSettings.cmpTargeting,
      fng_player_overlay_plugin: true,
      fng_player_comscore_plugin: cmpSettings.cmpPerformance,
      fng_player_youbora_plugin: true,
      fng_player_share_video_plugin: cmpSettings.cmpSocialMedia,
      fng_player_subtitles_plugin: cmpSettings.cmpFunctionalities,
      fng_player_related_videos_plugin: true,
      fng_player_ga_plugin: false,
      fng_player_agecheck_plugin: true,
      fng_player_unmute_plugin: true,
      service_loaded: cmpSettings.service_loaded
    };
    if (typeof window === 'object') {
      // Copy values from data to window.
      Object.keys(data).forEach(key => {
        window[key] = data[key];
      });
    }
    return data;
  },
  getCookiePermissions: (cookieType, settings) => {
    switch (cookieType) {
      case 'cmp_group_functionalities':
        return settings.cmpFunctionalities;
      case 'cmp_group_targeting':
        return settings.cmpTargeting;
      case 'cmp_group_social_media':
        return settings.cmpSocialMedia;
      case 'cmp_group_performance':
        return settings.cmpPerformance;
      case 'cmp_group_essential':
      default:
        return true;
    }
  },
  onOneTrustInteractionDone: () => {
    // Reload page if consent changes.
    const currentGroups = oneTrustConsentService.getGroups();
    if (previousGroups && previousGroups !== currentGroups) {
      document.location.reload();
    } else {
      previousGroups = currentGroups;
    }
  },
  listenToChanges: () => {
    eventService.addEventListener(
      'OneTrustInteractionDone',
      oneTrustConsentService.onOneTrustInteractionDone
    );
  },
  // Functions above are shared with src/domain/services/consentService/consentService.js
  //
  // Functions below are local to this service
  addScript: () => {
    if (conf.activate_cmp && conf.cmp_js_url) {
      addScript(conf.cmp_js_url, {
        'data-domain-script': conf.cmp_js_data_domain_script,
        'data-language': conf.langcodeIso639_1
      });
    }
  },

  dispatchInteractionDone: () => {
    const doneEvent = new Event('OneTrustInteractionDone');
    document.dispatchEvent(doneEvent);
    window.OneTrustInteractionDone = doneEvent;
  },

  dispatchCmpRequirementsReady: () => {
    const cmpReadyEvent = new Event('CmpRequirementsReady');
    document.dispatchEvent(cmpReadyEvent);
    window.CmpRequirementsReady = cmpReadyEvent;
  },

  // There is no CMP event to listen to, so we use a setInterval to check for its presence and dispatch an event.
  checkIfIabTcfIsLoaded: () => {
    const startDate = new Date().getTime();
    const intervalId = setInterval(() => {
      const currentDate = new Date().getTime();
      if (currentDate - startDate > 20 * 1000) {
        clearInterval(intervalId);
      }
      if (window.__tcfapi) {
        window.__tcfapi('ping', 2, e => {
          if (e.cmpLoaded === true && e.cmpStatus === 'loaded') {
            clearInterval(intervalId);
            oneTrustConsentService.dispatchCmpRequirementsReady();
            if (window.OneTrust && window.OneTrust.IsAlertBoxClosed()) {
              oneTrustConsentService.dispatchInteractionDone();
            }
          }
        });
      }
    });
  },

  setupOptanonWrapper: () => {
    window.OptanonWrapper = () => {
      if (!window.OptanonWrapperExecuted) {
        window.OptanonWrapperExecuted = true;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'OT_loaded' });
        window.OneTrust.OnConsentChanged(e =>
          oneTrustConsentService.dispatchInteractionDone(e)
        );
        if (conf.iab_compliance) {
          oneTrustConsentService.checkIfIabTcfIsLoaded();
        } else {
          oneTrustConsentService.dispatchCmpRequirementsReady();
          if (window.OneTrust && window.OneTrust.IsAlertBoxClosed()) {
            oneTrustConsentService.dispatchInteractionDone();
          }
        }
      }
    };
  }
};
