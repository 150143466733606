import { addScript } from '../utils/domUtils';
import { conf } from './configService';
import { eventService } from './eventService';

export const googleTagManager = {
  // This is done in src/components/HeadComponents/HeadComponents.jsx
  /*   setupDataLayer: () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
  },
 */
  addScript: () => {
    addScript(
      `https://www.googletagmanager.com/gtm.js?id=${conf.tagManagerContainerID}`
    );
  },

  init: () => {
    // googleTagManager.setupDataLayer();
    googleTagManager.addScript();
  },

  registerEventListeners: () => {
    eventService.addEventListener(
      'OneTrustInteractionDone',
      googleTagManager.init,
      {
        once: true
      }
    );
  }
};
