import { conf } from './configService';
import { previewService } from './previewService';
import { apiClientService } from './apiClientService';
// import { sleep } from '../../utils/sleep';

export const resourceService = {
  getJsonForPathname: async path => {
    if (typeof window === 'undefined') {
      return null;
    }
    // await resourceService.sleep(1000);
    let json = {};
    if (conf.isPreview) {
      json = await resourceService.getJsonForPathnameFromPreview(path);
    } else {
      json = await resourceService.getJsonForPathnameFromPageData(path);
    }

    return json;
  },

  getJsonForPathnameFromPageData: async path => {
    const { response: json } = await apiClientService.getJson(
      `/page-data${path}/page-data.json`
    );
    return json?.result?.pageContext?.node?.data?.content || null;
  },

  getJsonForPathnameFromPreview: async path => {
    const json = await previewService.getPreviewDataByNodeUrl(path);
    return json;
  }
};
