import { conf } from './configService';
import { domainService } from './domainService';
import { previewService } from './previewService';
import { urlService } from './urlService';

const normalizeUrl = url => {
  if (
    conf.isPreview &&
    !domainService.isLocalGatsbyDevDomain(window.location.hostname) &&
    url?.includes('/ajax/') &&
    !urlService.isAbsolute(url)
  ) {
    const previewDataEndpoint = previewService.getPreviewDataEndpoint();
    if (url.indexOf('/ajax/subscription_block') !== -1) {
      return url.replace(
        '/ajax/subscription_block',
        `${previewDataEndpoint}/${conf.langcode}/entity/block_content/subscription_block`
      );
    }
    return url.replace(
      '/ajax/',
      `${previewDataEndpoint}/${conf.langcode}/custom/ajax/`
    );
  }
  return url;
};

const normalizedFetch = (url, options = null) => {
  return fetch(normalizeUrl(url), options).then(response => {
    if (!response.ok) {
      throw new Error(response);
    }
    return response;
  });
};

const handle = (url, options = null) =>
  normalizedFetch(url, options)
    .then(response => (options.customDecodeJson ? response.json() : response))
    .then(response => ({ response, error: undefined }))
    .catch(error => {
      return Promise.resolve({ response: undefined, error });
    });

export const apiClientService = {
  // Raw function when you need then() and catch()
  fetch: (url, options = null) => normalizedFetch(url, options),

  // Sugared functions that never throw and return an object with response and error members.
  get: (url, options = null) => handle(url, { ...options, method: 'GET' }),
  getJson: (url, options = null) =>
    handle(url, { ...options, customDecodeJson: true }),
  post: (url, options = null) => handle(url, { ...options, method: 'POST' }),
  put: (url, options = null) => handle(url, { ...options, method: 'PUT' }),
  delete: (url, options = null) => handle(url, { ...options, method: 'DELETE' })
};
