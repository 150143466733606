/*
const disallowUndefinedProperties = obj => {
  const handler = {
    get(target, property) {
      if (property in target) {
        return target[property];
      }

      throw new Error(`Property '${property}' is not defined`);
    }
  };

  return new Proxy(obj, handler);
};
 */

const removeEmptyProperties = obj => {
  // eslint-disable-next-line no-param-reassign
  Object.keys(obj).forEach(k => !obj[k] && delete obj[k]);
  return obj;
};
/*
const renameObjectProperties = (obj, oldKey, newKey) => {
  if (oldKey !== newKey && obj[oldKey]) {
    Object.defineProperty(
      obj,
      newKey,
      Object.getOwnPropertyDescriptor(obj, oldKey)
    );
    // eslint-disable-next-line no-param-reassign
    delete obj[oldKey];
  }
};
 */
const mergeDeep = (...objects) => {
  const isObject = obj => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    const previous = prev;
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        previous[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        previous[key] = mergeDeep(pVal, oVal);
      } else {
        previous[key] = oVal;
      }
    });

    return previous;
  }, {});
};
/*
const isEmpty = object =>
  Object.keys(object).length === 0 && object.constructor === Object;
 */
// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
const deepFreeze = object => {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self
  // eslint-disable-next-line no-restricted-syntax
  for (const name of propNames) {
    const value = object[name];

    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
};

module.exports = {
  // disallowUndefinedProperties,
  removeEmptyProperties,
  // renameObjectProperties,
  mergeDeep,
  // isEmpty,
  deepFreeze
};
