/* eslint-disable no-underscore-dangle */
import { withPrefix } from 'gatsby';
import { domainService } from './src/domain/services/domainService';
import { getMeta } from './src/functions/getMeta';
import { gptService } from './src/domain/services/gptService';
import { conf, getHostConfig } from './src/domain/services/configService';
import { addScript } from './src/domain/utils/domUtils';
import { clientSideRedirectionService } from './src/domain/services/clientSideRedirectionService';
import { oneTrustConsentService } from './src/domain/services/consentService/oneTrustConsentService';
import { googleTagManager } from './src/domain/services/googleTagManagerService';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const setupRawConfig = async () => {
  if (!window.NATGEO_RAW_CONFIG) {
    let rawConfig = {};
    const hostConfig = getHostConfig();
    if (
      hostConfig.isPreview &&
      !domainService.isLocalGatsbyDevDomain(window.location.hostname)
    ) {
      rawConfig = await fetch(
        `/preview-data/${
          window.NATGEO_LANGCODE
        }/custom/config/raw-config.json?${Date.now()}`
      ).then(response => response.text());
    } else {
      rawConfig = await fetch(
        `${withPrefix('/config/raw-config.json')}?${window.NATGEO_CONFIG_HASH}`
      ).then(response => response.text());
    }
    window.NATGEO_RAW_CONFIG = rawConfig;
  }
};

// disableCorePrefetching is the first event that runs, before onClientEntry
export const disableCorePrefetching = async () => {
  await setupRawConfig();
  return conf.isMPA;
};

// Required with MPA to avoid pages getting into weird scroll positions
export const shouldUpdateScroll = () => !conf.isMPA;

// Called when the Gatsby browser runtime first *starts*.
// This is called before render happens.
// @see https://www.gatsbyjs.org/docs/browser-apis/#onClientEntry
export const onClientEntry = async () => {
  // window._satellite = { track: () => {} };

  // @todo uncomment when all sites are moved to MPA
  // await setupRawConfig();

  // DO NOT DELETE. It's used in some Blank Templates.
  // Ask Ricardo Baldo about it.
  window.___GAM = true;

  // Add dom util to insert scripts on DOM, used by Blank Templates.
  // eslint-disable-next-line no-underscore-dangle
  window.___ngAddScript = addScript;

  // Init GPT and enable its service before slots are defined
  // and display is called.
  gptService.init();

  document.dispatchEvent(new Event('gatsbyOnClientEntry'));
};

// Called when changing location is started.
// @see https://www.gatsbyjs.org/docs/browser-apis/#onPreRouteUpdate
export const onPreRouteUpdate = () => {
  window.___gatsbyIsUpdatingRoute = true;
  document.dispatchEvent(new Event('gatsbyOnPreRouteUpdate'));
};

// Called when the user changes routes
// @see https://www.gatsbyjs.org/docs/browser-apis/#onRouteUpdate
export const onRouteUpdate = () => {
  delete window.___gatsbyIsUpdatingRoute;
  document.dispatchEvent(new Event('gatsbyOnRouteUpdate'));
  clientSideRedirectionService.evaluate(window.location.pathname);
};

// Between these two events, React render/rehydrate happens.

// Called when the initial (but not subsequent) render of Gatsby App is *done* on the client.
// This is called after render happens, and only once, on first render
// @see https://www.gatsbyjs.org/docs/browser-apis/#onInitialClientRender
export const onInitialClientRender = () => {
  // GTM global functions:
  window.gtmCustomFunctions = {};
  window.getMeta = getMeta; // Remove once all gtm tasks are migrated
  window.gtmCustomFunctions.getMeta = getMeta;

  // Listen for OneTrustInteractionDone
  gptService.registerEventListeners();
  googleTagManager.registerEventListeners();
  oneTrustConsentService.listenToChanges();

  // Countries with CMP (GDPR) and with or without IAB.
  if (conf.activate_cmp) {
    oneTrustConsentService.setupOptanonWrapper();
    oneTrustConsentService.addScript();
  }
  // Countries without CMP (GDPR).
  else {
    oneTrustConsentService.dispatchCmpRequirementsReady();
    oneTrustConsentService.dispatchInteractionDone();
  }

  if (conf.bundle_code_custom_js) {
    try {
      // eslint-disable-next-line no-eval
      eval(conf.bundle_code_custom_js);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error on bundle_code_custom_js: ', e);
    }
  }

  window.___gatsbyInitialClientRenderDone = true;
  document.dispatchEvent(new Event('gatsbyOnInitialClientRender'));
};

// @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#registerServiceWorker
// Register service worker only on non-cms domains.
export const registerServiceWorker = async () => {
  await setupRawConfig();
  return !domainService.isRunningOnCMS();
};

/*
// Force React to throw real errors when propTypes validation fails
// @see https://stackoverflow.com/questions/27006782/force-reactjs-to-throw-real-errors-when-proptypes-validation-fails
/* if (process.env.NODE_ENV === 'development') {
  const { error } = console;
  // eslint-disable-next-line no-console
  console.error = (...args) => {
    if (/(Invalid prop|Failed prop|"key" prop)/.test(args[0])) {
      throw new Error(args[0]);
    }
    error.apply(console, args);
  };
}
 */
