import { navigate } from 'gatsby';
import { resourceService } from './resourceService';

const navigationHasFailed = () => !document.querySelector('.page-content');

export const clientSideRedirectionService = {
  evaluate: async path => {
    if (navigationHasFailed()) {
      resourceService.getJsonForPathname(path).then(json => {
        if (json?.url?.path && path !== json.url.path) {
          navigate(json.url.path);
        }
      });
    }
  }
};
