import { conf } from './configService';
import { domainService } from './domainService';
import { apiClientService } from './apiClientService';

export const previewService = {
  getPreviewDataDomain: () => {
    let previewDataDomain;
    if (domainService.isLocalDomain(window.location.hostname)) {
      previewDataDomain = conf.localDrupalDevDomain;
    } else if (domainService.isRunningOnCMS()) {
      previewDataDomain = window.location.hostname;
    }
    return previewDataDomain;
  },

  getPreviewPathResolverDomain: () => previewService.getPreviewDataDomain(),

  getPreviewDataEndpoint: () => {
    return `/preview-data`;
  },

  getPreviewDataByNodeUrl: async url => {
    const pathResolverEndpoint = `/page-data${url}/page-data.json`;
    const { response } = await apiClientService.getJson(pathResolverEndpoint);
    return response?.result?.pageContext?.node?.data?.content;
  }
};
