const { themeConfig } = require('./themeConfig');
const { isNullOrUndefined, isString } = require('../utils/typeUtils');

// Create helper structures for breakpoints
const bpKeys = Object.keys(themeConfig.breakpoints);
const sortedBreakpoints = bpKeys.map((name, index) => {
  const prevBpName = bpKeys[index - 1];
  // const nextBpName = bpKeys[index + 1];
  return {
    index,
    name,
    minWidth: prevBpName ? themeConfig.breakpoints[prevBpName] : 0,
    width: themeConfig.breakpoints[name],
    maxWidth:
      name !== 'large_desktop' ? themeConfig.breakpoints[name] - 1 : null
  };
});
const breakpointsByName = {};
sortedBreakpoints.forEach(bp => {
  breakpointsByName[bp.name] = bp;
});

const mq = {};
const mqMax = {};
const mqMinMax = {};
const mqMinMaxRaw = {};
sortedBreakpoints.forEach((bp, index) => {
  const nextBp = sortedBreakpoints[index + 1];
  if (nextBp) {
    mq[nextBp.name] = `@media (min-width: ${bp.width}px)`;
  }
  // Do not use large_desktop to avoid unstyled elements if the
  // screen width is larger than large_desktop width
  if (bp.name !== 'large_desktop') {
    mqMax[bp.name] = `@media (max-width: ${bp.maxWidth}px)`;
  }

  // Calculate mqMinMax
  const mqMinMaxRawParts = [];
  if (bp.minWidth) {
    mqMinMaxRawParts.push(`(min-width: ${bp.minWidth}px)`);
  }
  if (bp.maxWidth) {
    mqMinMaxRawParts.push(`(max-width: ${bp.maxWidth}px)`);
  }
  mqMinMaxRaw[bp.name] = mqMinMaxRawParts.join(' and ');
  mqMinMax[bp.name] = `@media ${mqMinMaxRaw[bp.name]}`;
});

const getProperty = (object, property, objectName, options) => {
  const result = object[property];
  if (result === undefined) {
    // Before throwing, check if property matches one of available regex
    if (
      options?.validRegex?.length &&
      options.validRegex.find(regex => regex.test(property))
    ) {
      return property;
    }

    if (isString(property) && property.startsWith('$')) {
      throw Error(
        `Property "${property}" not used in a multi property context. Please use "${property.substr(
          1
        )}".`
      );
    }
    throw Error(`Property "${property}" not found in "theme.${objectName}".`);
  }
  return result;
};

const getPropertyCanonicalName = p => (p.startsWith('$') ? p.substr(1) : p);

const isMultiPropertyString = property =>
  isString(property) && property.indexOf(' ') !== -1;

const resolveMultiPropertyString = (object, property, objectName, options) => {
  if (!isString(property)) {
    throw Error(
      `Property "${property}" is not a string and should be used in a non multi property context. Please use theme.${objectName}(${property}) instead of theme.${objectName}.parse("${property}")`
    );
  }
  const properties = property.trim().split(' ');

  if (properties.length === 1) {
    if (object[getPropertyCanonicalName(properties[0])]) {
      throw Error(
        `Property "${property}" should be used in a non multi property context. Please use theme.${objectName}("${getPropertyCanonicalName(
          properties[0]
        )}") instead of theme.${objectName}.parse("${property}")`
      );
    }
  }

  return properties
    .map(p => {
      if (p.startsWith('$')) {
        return getProperty(object, p.substr(1), objectName, options);
      }
      if (p.startsWith('-$')) {
        return `-${getProperty(object, p.substr(2), objectName, options)}`;
      }
      if (object[p]) {
        throw Error(
          `Property "${p}" is used in a multi property context. Please use theme.${objectName}.parse("${property.replace(
            p,
            `$${p}`
          )}") or theme.${objectName}("${p}")`
        );
      }
      return p;
    })
    .join(' ');
};

const resolve = (
  object,
  property,
  objectName,
  options = { isMultiProperty: false, validRegex: [] }
) => {
  // Return all data if no property passed.
  if (isNullOrUndefined(property)) {
    return object;
  }

  // If it's an array, iterate over all items
  if (Array.isArray(property)) {
    return property.map(p => {
      // Early opt-out when is used in conjunction with facePaint and
      // values like ['base', null, null, 'xl']
      if (isNullOrUndefined(p)) {
        return p;
      }

      // Parse string or call getProperty() otherwise
      // We use isMultiPropertyString() because being an array it can
      // contain integers, strings, etc and we cannot define "isMultiProperty"
      // per each item in the array.
      return options.isMultiProperty && isMultiPropertyString(p)
        ? resolveMultiPropertyString(object, p, objectName, {
            validRegex: options.validRegex
          })
        : getProperty(object, p, objectName, {
            validRegex: options.validRegex
          });
    });
  }

  // If it's not an array, parse string or call getProperty() otherwise.
  // We don't use isMultiPropertyString() because is not an array so you
  // must know, ahead of time, what kind of data are you passing.
  // You can use parseUnknownData() when input data can be of any type.
  if (options.isMultiProperty) {
    return resolveMultiPropertyString(object, property, objectName, {
      validRegex: options.validRegex
    });
  }
  return getProperty(object, property, objectName, {
    validRegex: options.validRegex
  });
};

// Functions inside theme object are called "base resolvers".
const theme = {
  grid: {
    ...themeConfig.grid,
    /**
     * @param {('fluid'|'main'|'inner')} name
     */
    containers: name =>
      resolve(themeConfig.grid.containers, name, 'grid.containers'),
    /**
     * @param {(0|1|2|3|4|5|6|7|8|9|10|11|12)} width
     */
    columns: width =>
      resolve(themeConfig.grid.columns.values, width, 'grid.columns.values', {
        validRegex: themeConfig.grid.columns.validRegex
      }),
    /**
     * @param {('xl'|'l'|'base'|'s'|'xs'|'none')} scale - xl: 2rem, l: 1.5rem, base: 1rem, s: 0.5rem, xs: 0.25rem, none: 0
     */
    gutter: scale => resolve(themeConfig.grid.gutter, scale, 'grid.gutter')
  },
  /**
   * @param {('xl5'|'xl4'|'xl3'|'xl2'|'xl'|'l'|'base'|'s'|'xs'|'xs2'|'xs3')} scale - xls5: 8rem, xl4: 6rem, xl3: 5rem, xl2: 4rem, xl: 3rem, l: 2.5rem, base: 2rem, s: 1.5rem, xs: 1rem, xs2: 0.5rem, xs3: 0.25rem
   */
  spacing: scale => resolve(themeConfig.spacing, scale, 'spacing'),
  font: {
    /**
     * @param {('xl9'|'xl8'|'xl7'|'xl6'|'xl5'|'xl4'|'xl3'|'xl2_5'|'xl2'|'xl1'|'xl'|'l'|'base1'|'base'|'s'|'xs'|'xs2')} scale - xl9: 8rem, xl8: 7rem, xl7: 6rem, xl6: 5rem, xl5: 4rem, xl4: 3rem, xl3: 2.5rem, xl2_5: 2.25rem,xl2: 2rem,xl1: 1.75rem, xl: 1.5rem,l: 1.1875rem, base1: 1.125rem(18px) base: 1rem, s: 0.875rem, xs: 0.75rem, xs2: 0.6875rem     */

    size: scale => resolve(themeConfig.font.size, scale, 'font.size'),
    /**
     * @param {('xl'|'l'|'base'|'s')} scale - xl: 900, l: 700, base: 400, s: 100
     */
    weight: scale => resolve(themeConfig.font.weight, scale, 'font.weight'),
    /**
     * @param {('regular'|'regularWeb'|'medium'|'bold'|'boldWeb'|'paragraphs')} name - regular: GeoEditRegular, regularWeb: GeoWebRegular, medium: GeoEditMedium, bold: GeoEditBold, boldWeb: GeoWebBold, paragraphs: Georgia
     */
    family: name =>
      `font-family: ${resolve(themeConfig.font.family, name, 'font.family')}; ${
        name.includes('Web') && `font-feature-settings: 'swsh';`
      }
    `
  },
  /**
   * @param {('xl'|'l'|'base'|'s'|'xs'|'xs2')} scale - xl: '0.75rem' (12px), l: '0.375rem' (6px), base: '0.1875rem' (3px), s: '0.03125rem' (0.5px), xs: '0.015625rem' (0.25px), xs2: '0.00625rem' (0.1px)
   */
  letterSpacing: scale =>
    resolve(themeConfig.letterSpacing, scale, 'letterSpacing'),
  line: {
    /**
     * @param {('xl'|'l'|'base'|'s')} scale - xl: 1.9, l: 1.6, base: 1.4, s: 1.2
     */
    height: scale => resolve(themeConfig.line.height, scale, 'line.height')
  },
  /**
   * @param {('cookies'|'modal'|'share'|'above'|'base'|'below')} name - cookies: 4, modal: 3, share: 2, above: 1, base: 0, below: -1
   */
  zIndex: name => resolve(themeConfig.zIndex, name, 'zIndex'),
  /**
   * @property {string} mobile
   * @property {string} tablet - `@media (min-width: 768px)`
   * @property {string} small_desktop - `@media (min-width: 1024px)`
   * @property {string} large_desktop - `@media (min-width: 1280px)`
   */
  mq,
  /**
   * @property {string} mobile - `@media (max-width: 767px)`
   * @property {string} tablet - `@media (max-width: 1023px)`
   * @property {string} small_desktop - `@media (max-width: 1279px)`
   * @property {string} large_desktop
   */
  mqMax,
  /**
   * @property {string} mobile - `@media (max-width: 767px)`
   * @property {string} tablet - `@media (min-width: 768px) and (max-width: 1023px)`
   * @property {string} small_desktop - `@media (min-width: 1024px) and (max-width: 1279px)`
   * @property {string} large_desktop - `@media (min-width: 1280px)`
   */
  mqMinMax,
  /**
   * @property {string} mobile - `(max-width: 767px)`
   * @property {string} tablet - `(min-width: 768px) and (max-width: 1023px)`
   * @property {string} small_desktop - `(min-width: 1024px) and (max-width: 1279px)`
   * @property {string} large_desktop - `(min-width: 1280px)`
   */
  mqMinMaxRaw,
  breakpoints: {
    sorted: sortedBreakpoints,
    sortedAsc: sortedBreakpoints,
    sortedDesc: sortedBreakpoints.slice().reverse(),
    byName: breakpointsByName,
    imageSteps: themeConfig.breakpointImageSteps
  }
};

theme.grid.gutter.parse = scale =>
  resolve(themeConfig.grid.gutter, scale, 'grid.gutter', {
    isMultiProperty: true,
    validRegex: [/px$/, /rem$/, /^calc\(.*\)$/]
  });

theme.grid.gutter.parseUnknownData = scale =>
  Array.isArray(scale) ||
  isMultiPropertyString(scale) ||
  scale.indexOf('px') !== -1
    ? theme.grid.gutter.parse(scale)
    : theme.grid.gutter(scale);

theme.spacing.parse = scale =>
  resolve(themeConfig.spacing, scale, 'spacing', {
    isMultiProperty: true,
    validRegex: [/px$/, /rem$/, /^calc\(.*\)$/]
  });

theme.spacing.parseUnknownData = scale =>
  Array.isArray(scale) ||
  isMultiPropertyString(scale) ||
  scale.indexOf('px') !== -1
    ? theme.spacing.parse(scale)
    : theme.spacing(scale);

module.exports.theme = theme;
