export const noCmpConsentService = {
  getGroups: () => {
    return '';
  },
  allowsFunctionalities: () => true,

  allowsTargeting: () => true,

  allowsSocialMedia: () => true,

  allowsPerformance: () => true,

  getPlayerData: () => {
    const data = {
      fng_player_gam_plugin: true,
      fng_player_overlay_plugin: true,
      fng_player_comscore_plugin: true,
      fng_player_youbora_plugin: true,
      fng_player_share_video_plugin: true,
      fng_player_subtitles_plugin: true,
      fng_player_related_videos_plugin: false,
      fng_player_ga_plugin: true,
      fng_player_agecheck_plugin: true,
      fng_player_unmute_plugin: true,
      service_loaded: true
    };

    if (typeof window === 'object') {
      // Copy values from data to window.
      Object.keys(data).forEach(key => {
        window[key] = data[key];
      });
    }

    return data;
  },
  getCmpSettings: () => {
    const data = {
      service_loaded: false
    };
    data.cmpPerformance = noCmpConsentService.allowsPerformance();
    data.cmpFunctionalities = noCmpConsentService.allowsFunctionalities();
    data.cmpTargeting = noCmpConsentService.allowsTargeting();
    data.cmpSocialMedia = noCmpConsentService.allowsSocialMedia();

    return data;
  },
  getCookiePermissions() {
    return true;
  },
  listenToChanges() {
    // noop
  }
};
