/**
 * A wrapper over document.addEventListener and document.removeEventListener
 * that, before registering a listener, checks for the existence of a variable
 * in window with the same name as the event, to detect if the event has already
 * been dispatched.
 */

// Use a WeakMap to be able to use functions as keys.
const listenerWrappers = new WeakMap();

export const eventService = {
  addEventListener: (
    type,
    listener,
    options = { once: false, addFlag: true, useWindow: false }
  ) => {
    const attachElement = options.useWindow ? window : document;
    // If the event is already dispatched, execute the listener.
    if (window[type]) {
      listener();
      // If it only needs to be executed once, return now to avoid
      // setting a real event listener.
      if (options?.once) {
        return;
      }
    }

    // Create a wrapper over listener() to be able to set a flag
    // in window to tell the event is already set.
    if (!listenerWrappers.has(listener)) {
      // Save the listener wrapper to be able to remove it later.
      listenerWrappers.set(listener, event => {
        if (options?.addFlag) {
          window[event.type] = event;
        }
        listener(event);
      });
    }
    const listenerWrapper = listenerWrappers.get(listener);

    // Set a listener if:
    // 1) Not yet dispatched
    // 2) Already dispatched, but "once" option is not set
    attachElement.addEventListener(type, listenerWrapper, options);
  },

  removeEventListener: (
    type,
    listener,
    options = { removeFlag: false, useWindow: false }
  ) => {
    const attachElement = options.useWindow ? window : document;
    if (options && options.removeFlag) {
      eventService.removeFlag(type);
    }

    // Remove the raw listener if no wrapper is available.
    const listenerWrapper = listenerWrappers.get(listener) || listener;

    attachElement.removeEventListener(type, listenerWrapper);

    // Delete the wrapper. No need to check if it really exists.
    listenerWrappers.delete(listener);
  },

  dispatchEvent: (type, detail = null, options = { addFlag: true }) => {
    const event = new CustomEvent(type, {
      detail
    });
    document.dispatchEvent(event);
    if (options?.addFlag) {
      window[type] = event;
    }
  },

  removeFlag: type => delete window[type]
};
