const isNull = x => x === null && x !== undefined;
const isUndefined = x => x === undefined;
const isNullOrUndefined = x => isNull(x) || isUndefined(x);
const isString = x => typeof x === 'string';
const isBoolean = x => typeof x === 'boolean';
const isObject = x => x != null && x.constructor.name === 'Object';
const isArray = x => Array.isArray(x);

module.exports = {
  isNull,
  isUndefined,
  isNullOrUndefined,
  isString,
  isBoolean,
  isObject,
  isArray
};
