const { conf } = require('./configService');

// TODO rename to hostnameService
const domainService = {
  isLocalDrupalDevDomain: hostname =>
    hostname.endsWith(conf.localDrupalDevDomain),

  isLocalGatsbyDevDomain: hostname =>
    ['localhost', '[::1]', '0.0.0.0'].includes(hostname) || // [::1] is the IPv6 localhost address.
    hostname.startsWith('192.168') ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),

  isLocalDomain: hostname =>
    domainService.isLocalGatsbyDevDomain(hostname) ||
    domainService.isLocalDrupalDevDomain(hostname),

  getDrupalDomain: hostname => {
    if (conf.canonicalDomain === hostname) {
      return hostname;
    }
    const regex = new RegExp('^.*(stage-|prod-|dev-|origin-).*$');
    const results = regex.exec(hostname);

    if (!results) {
      return hostname;
    }
    let environment = results[1].replace('-', '');
    if (environment === 'prod') {
      environment = 'origin';
    }
    let mode = '';
    if (hostname.indexOf('deploy-') !== -1) {
      mode = 'deploy-';
    } else if (environment === 'origin') {
      return conf.canonicalDomain;
    }
    return `cms-${mode}${environment}-${conf.siteCountryCode}.ngeo.com`;
  },

  isRunningOnCMS: () => {
    if (typeof window === 'object') {
      return (
        window.location.hostname.indexOf('cms-') !== -1 ||
        domainService.isLocalDrupalDevDomain(window.location.hostname)
      );
    }
    return false;
  },

  getEndpointDomain: () => {
    if (typeof window === 'object') {
      if (!domainService.isLocalDomain(window.location.hostname)) {
        return `https://${domainService.getDrupalDomain(
          window.location.hostname
        )}`;
      }
    }
    return `https://cms-stage-${conf.siteCountryCode}.ngeo.com`;
  },

  getCurrentDomain: () =>
    typeof window === 'object'
      ? window.location.hostname
      : conf.canonicalDomain,

  // Even this method is about "origin" and not "hostname"/"domain", we keep it
  // here since it doesn't make sense to create a new "originService" for just
  // one method.
  // "origin" contains protocol + hostname + port (http://localhost:8000 or https://www.example.com)
  getCurrentOrigin: () =>
    typeof window === 'object'
      ? window.location.origin
      : `https://${conf.canonicalDomain}`,

  isExternal: href => {
    let regExp = new RegExp(
      `//(${conf.canonicalDomain.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})($|/)`
    );
    if (typeof window === 'object') {
      regExp = new RegExp(
        `//(${window.location.hostname.replace(
          /[.*+?^${}()|[\]\\]/g,
          '\\$&'
        )}|${conf.canonicalDomain.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})($|/)`
      );
    }

    const domainInHref = regExp.test(href);
    let isLocal = href.substring(0, 4) === 'http' ? domainInHref : true;
    if (isLocal) {
      const hrefFirstChar = domainService.removeDomain(href).substring(0, 1);
      isLocal = hrefFirstChar === '/' || hrefFirstChar === '#';
    }
    return !isLocal;
  },

  isCanonicalDomain: hostname => hostname === conf.canonicalDomain,

  isStageCmsDomain: hostname => hostname.match(/^cms-stage-(\w{2}).ngeo.com$/),

  removeDomain: href => href.replace(/.*\/\/[^/]*/, '')
};

module.exports = { domainService };
