// This file should not be directly accessed across the project.
// It should be only imported by theme.js

const { deepFreeze } = require('../utils/objectUtils');

const columns = {
  0: 0
};
for (let i = 1; i <= 12; i += 1) {
  columns[i] = `${(i / 12) * 100}%`;
}
const themeConfig = deepFreeze({
  grid: {
    columns: { values: columns, validRegex: [/px$/, /rem$/, /^calc\(.*\)$/] },
    containers: {
      fluid: {
        maxWidth: '100%',
        margin: 0,
        padding: '0',
        childrenMarginBottom: 'base'
      },
      main: {
        maxWidth: '100rem',
        margin: 0,
        padding: ['0 $base', '0 $l', '0 $xl2'],
        childrenMarginBottom: 'base'
      },
      inner: {
        maxWidth: '80.5rem',
        margin: 0,
        padding: ['0 $base', '0 $l', '0 $xl2'],
        childrenMarginBottom: 'base'
      }
    },
    // For spacing *between* grid columns.
    // Multiply it by two to get the real gutter (gutters are created by joining two margins)
    gutter: {
      xl: '2rem',
      l: '1.5rem',
      base: '1rem',
      s: '0.5rem',
      xs: '0.25rem',
      none: '0'
    }
  },
  // For spacing *inside* grid columns
  spacing: {
    xl5: '8rem',
    xl4: '6rem',
    xl3: '5rem',
    xl2: '4rem',
    xl: '3rem',
    l: '2.5rem',
    base: '2rem',
    s: '1.5rem',
    xs: '1rem',
    xs2: '0.5rem',
    xs3: '0.25rem'
  },
  font: {
    family: {
      regular: 'GeoEditRegular, sans-serif',
      regularWeb: 'GeoWebRegular, sans-serif',
      medium: 'GeoEditMedium, sans-serif',
      bold: 'GeoEditBold, sans-serif',
      boldWeb: 'GeoWebBold, sans-serif',
      paragraphs: 'Georgia, Times, "Times New Roman", serif'
    },
    // Pseudo modular scale based on
    // base: 16px
    // ratio: 1.125
    size: {
      xl9: '8rem', // 128px
      xl8: '7rem', // 112px
      xl7: '6rem', // 96px
      xl6: '5rem', // 80px
      xl5: '4rem', // 64px
      xl4: '3rem', // 48px
      xl3: '2.5rem', // 40px
      xl2_5: '2,25rem', // 36px
      xl2: '2rem', // 32px
      xl1: '1.75rem', // 28px
      xl: '1.5rem', // 24px
      l: '1.1875rem', // 19px
      base1: '1.125rem', // 18px
      base: '1rem', // 16px
      s: '0.875rem', // 14px
      xs: '0.75rem', // 12px
      xs2: '0.6875rem', // 11px
      xs3: '0.625rem' // 10px
    },
    weight: {
      xl: 900,
      l: 700,
      base: 400,
      s: 100
    }
  },
  letterSpacing: {
    xl: '0.75rem', // 12px
    l: '0.375rem', // 6px
    base: '0.1875rem', // 3px
    s: '0.03125rem', // 0.5px
    xs: '0.015625rem', // 0.25px
    xs2: '0.00625rem' // 0.1px
  },
  line: {
    height: {
      xl: 1.9,
      l: 1.6,
      base: 1.4,
      s: 1.2
    }
  },
  breakpoints: {
    mobile: 768,
    tablet: 1024,
    small_desktop: 1280,
    large_desktop: 1600
  },
  breakpointImageSteps: {
    // Do not use any width below 320px (Iphone 5) or larger that 500px,
    // since very large smartphones are usually not larger that 450px
    // 320: Iphone 5
    // 360: Moto G4. This is the one used by Lighthouse and alike,
    //      so it's important to have it optimized for that screen size
    // Iphone 12 Pro Max: 428
    mobile: [320, 360, 430, 500],
    tablet: [900],
    small_desktop: [1150],
    large_desktop: [1450]
  },
  zIndex: {
    cookies: 4,
    modal: 3,
    share: 2,
    above: 1,
    base: 0,
    below: -1
  }
});

module.exports.themeConfig = themeConfig;
