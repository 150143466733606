import { conf } from '../configService';
import { noCmpConsentService } from './noCmpConsentService';
import { oneTrustConsentService } from './oneTrustConsentService';

const cmpService = () =>
  ['br', 'mx'].includes(conf.siteCountryCode)
    ? noCmpConsentService
    : oneTrustConsentService;

export const consentService = {
  getGroups: () => cmpService().getGroups(),
  allowsFunctionalities: () => cmpService().allowsFunctionalities(),
  allowsTargeting: () => cmpService().allowsTargeting(),
  allowsSocialMedia: () => cmpService().allowsSocialMedia(),
  allowsPerformance: () => cmpService().allowsPerformance(),
  getPlayerData: () => cmpService().getPlayerData(),
  getCmpSettings: () => cmpService().getCmpSettings(),
  getCookiePermissions: (cookieType, settings) =>
    cmpService().getCookiePermissions(cookieType, settings),
  listenToChanges: () => cmpService().listenToChanges()
};
