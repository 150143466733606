const { processConfig } = require('./configHelper');
const { deepFreeze } = require('../../utils/objectUtils');

let config = null;

const isLambda =
  !!(/* LAMBDA_START */ process.env.AWS_LAMBDA_RUNTIME_API /* LAMBDA_END */);

const getLangcode = () =>
  typeof window === 'undefined'
    ? /* LANG_START */ process.env.NATGEO_LANGCODE /* LANG_END */
    : window.NATGEO_LANGCODE;

const getHostConfig = () => {
  // eslint-disable-next-line global-require
  return require('./config/hostConfig.json');
};

const getRawConfig = () =>
  typeof window === 'undefined'
    ? /* CONF_START */ process.env.NATGEO_RAW_CONFIG /* CONF_END */
    : window.NATGEO_RAW_CONFIG;

const getEnvRawConfigReload = () =>
  /* CONF_RELOAD_START1 */ process.env
    .NATGEO_CONF_RELOAD; /* CONF_RELOAD_END1 */

const setEnvRawConfigReload = value => {
  /* CONF_RELOAD_START2 */ process.env.NATGEO_CONF_RELOAD /* CONF_RELOAD_END2 */ =
    value;
};

const configNeedsReload = () => {
  if (config) {
    if (isLambda && getEnvRawConfigReload() === 'true') {
      return true;
    }
    return false;
  }
  return true;
};

const getConfig = () => {
  if (configNeedsReload()) {
    if (isLambda) {
      setEnvRawConfigReload('false');
    }
    const hostConfig = getHostConfig();
    const rawConfigString = getRawConfig();
    const rawConfig = JSON.parse(rawConfigString);
    const langcode = getLangcode();
    config = deepFreeze(processConfig(hostConfig, rawConfig, langcode));
  }
  return config;
};

const proxyHandler = {
  get(target, prop) {
    if (prop !== '$$typeof') {
      return getConfig()[prop];
    }
    // eslint-disable-next-line prefer-rest-params
    return Reflect.get(...arguments);
  }
};

// const localeKeys = new Set();

const getLocale = key => {
  if (!config) {
    config = getConfig();
  }
  // if (!localeKeys.has(key)) {
  //   localeKeys.add(key);
  //   console.log('******', key);
  // }
  return config.locale[key] || key;
};

module.exports = {
  conf: new Proxy({}, proxyHandler),
  getHostConfig,
  t: getLocale
};
